// import React from "react";
import './Experience.css';
import {Icon} from '../components/Button';
import ExperienceCard from '../components/ExperienceCard';
import highschoolpic from '../images/2018-09-lfay-hanoi-vietnam-474x246.jpg';
import collegepic from '../images/yale-nus.jpg';
import cartogram from '../images/worldcartogram.png';
import pythonicon from '../images/python.png';
import matplotlib from '../images/matplotlib.png';
import rpi from '../images/raspberry-pi.png';
import mysql from '../images/mysql.png';
import cpp from '../images/c.png';
import cgal from '../images/cgal.png';
import labmonitor from '../images/lab_monitor_screenshot.png';
import holmusk from '../images/holmusk.jfif';
import haskell from '../images/haskell.png';
import postgre from '../images/postgre.png';

function Experience(props) {
  return (
    <div id='experience' className='experience-wrapper'>
      <div className='experience'>

        <h1 className='experience-title'>My journey</h1>

        <ExperienceCard
          title='Lycée Français Alexandre Yersin'
          date='2016 - 2020'
          image={highschoolpic}
          img_desc="high school"
          desc='I spent my formative high school years here, where the language of
          instruction is French.
          I sat the French Baccalaureate and received a 20 out of 20 overall score, graduating
          with highest honours. I was also selected as a recipient of the AEFE Excellence-Major
          scholarship that covers tuition fees and living expenses for five years of higher
          studies in France, an honour that I ultimately turned down for Yale-NUS College in Singapore.'
        />

        <ExperienceCard
          title='Yale-NUS College'
          date='2020 - 2024 (expected)'
          image={collegepic}
          img_desc="college"
          desc="The next stop on my journey is Yale-NUS College, where I am working towards a Bachelor
          of Science. I am able to afford my studies here thanks to the Yale-NUS Donor Study Award.
          The liberal arts curriculum allowed me to explore a variety of topics such as literature and
          social studies. I am also currently the Editor-in-Chief of the Yale-NUS Undergraduate Journal,
          a publication dedicated to making research accessible for undergraduates."
        />

        <ExperienceCard
          title='go-cart.io - Student Researcher'
          date='December 2020 - present'
          image={cartogram}
          img_desc="cartogram"
          desc="Professor Michael Gastner at Yale-NUS College developed a method based on fluid motion
          to generate cartograms, which are maps where areas are proportional to some statistical data
          (e.g. population). I worked on and implemented an algorithm in C++ for projecting these cartograms,
          which involves densifying the map (adding more points) and subdividing it into triangles that
          can then be used to translate map coordinates via affine transformations. This allows for
          intersection-free cartograms!"
          buttons={
            <div className='experience-card-buttons'>
              <Icon icon={cpp} alt="C++" />
              <Icon icon={cgal} alt="CGAL" />
            </div>
          }
        />

        <ExperienceCard
          title='Olsen Lab - Student Researcher'
          date='May 2021 - January 2022'
          image={labmonitor}
          img_desc="lab monitor GUI"
          desc="Due to the sensitive nature of Professor Ben Olsen's experiments, monitoring lab conditions is a must.
          I have set up a system for monitoring various conditions such as temperatures and humidity around the lab
          using Raspberry Pi's that send their data to the MySQL server hosted in the lab. I have also written an
          app with a GUI to visualise this data as graphs."
          buttons={
            <div className='experience-card-buttons'>
              <Icon icon={pythonicon} alt="Python" />
              <Icon icon={matplotlib} alt="Matplotlib" />
              <Icon icon={mysql} alt="MySQL" />
              <Icon icon={rpi} alt="Raspberry Pi" />
            </div>
          }
        />

        <ExperienceCard
          title='Holmusk - Software Engineering Intern'
          date='May 2022 - July 2022'
          image={holmusk}
          img_desc="holmusk logo"
          desc="Over the summer, I interned at Holmusk, a health technology company that aims to
          write software to solve behavioral and mental health issues. During my time here, I have written
          code to retrieve patient data for clinical trials, as well as implemented server endpoints to
          facilitate admin tasks for NeuroBlu Research. Most importantly, I learned about Haskell, and how
          functional programming can be effectively used in production. Now, I'm excited to learn more about
          functional languages and how they can help people write better code!"
          buttons={
            <div className='experience-card-buttons'>
              <Icon icon={haskell} alt="Haskell" />
              <Icon icon={postgre} alt="PostgreSQL" />
            </div>
          }
        />

      </div>
    </div>
  )
}

export default Experience;